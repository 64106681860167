import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import './placeOrder.css';
import {Form} from 'react-bootstrap';
import {Modal} from '@mui/material';

const initialState = {
    order_typeError: '',
    paper_typeError: '',
    academic_yearError: '',
    deadlineError: '',
    spacingError: '',
    disciplineError: '',
    chartsSlidesPagesError: ''
};

const token = localStorage.getItem('token')

export default function PlaceOrder(){

    const [pages, setPages] = useState(1);
    const [charts, setCharts] = useState(0);
    const [slides, setSlides] = useState(0);
    const [references, setReferences] = useState(0);
    const [spacing, setSpacing] = useState('Double');
    const [redirect, setRedirect] = useState(false);
    const [ref, setref] = useState('');
    const [error, setError] = useState(false);
    const [validators, setValidators] = useState(initialState);
    const [open, setOpen] = useState(false);
    const [orderType, setOrderType] = useState('Writing');

    const [promoCode, setPromoCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    const [curStep, setCurStep] = useState('FirstStep');

    const [details, setDetails] = useState({
        pages: 0,
        charts: 0,
        slides: 0,
        paper_type: '',
        subject: '',
        instructions: '',
        instruction_file: '',
        paper_format: '',
        references: 0,
        order_type: 'Writing',
        academic_year: '',
        title: '',
        deadline: '',
        paper_level: '',
        upgrade: '',
        task_size: '',
        programming_category: '',
        prog_language: '',
        software: '',
        discipline: '',
        spacing: '',
        amount: 0
    });

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'To The Moon Experts - Order'
    },[]);

    useEffect(() => {
        setDetails(details => ({
            ...details, pages: pages, charts: charts, slides: slides, references: references
        }))

    }, [slides, charts, pages, references]);

    useEffect(() => {
        if(orderType === 'Writing'){
            setDetails(prevState => ({
                ...prevState, paper_level: 'Basic', software: '', subject: '', programming_category: '', prog_language: '', task_size: '', paper_type: 'Essay', paper_format: 'MLA', academic_year: 'High School', spacing: 'Double',  discipline: 'Philosophy', deadline: '4 Hours'
            }))
            setPages(1)
        }else if(orderType === 'Programming Assignment'){
            setDetails(prevState => ({
                ...prevState, paper_level: '', subject: '', paper_format: '', academic_year: '', spacing: '', title: '', paper_type: '', upgrade: '', discipline: '', deadline: '24 Hours', task_size: 'Extra small', software: '', pages: 0, references: 0, charts: 0, slides: 0
            }))
            setPages(0)
            setCharts(0)
            setSlides(0)
            setReferences(0)
        }else if(orderType === 'Calculations Assignment'){
            setDetails(prevState => ({
                ...prevState, discipline: '', paper_level: '', paper_format: '', academic_year: '', title: '', upgrade: '', paper_type: '', programming_category: '', prog_language: '', deadline: '24 Hours', task_size: 'Extra small', spacing: '', pages: 0, references: 0, charts: 0, slides: 0
            }))
            setPages(0)
            setCharts(0)
            setSlides(0)
            setReferences(0)
        }
    },[orderType]);

    const incrementPages = (event) =>{
        event.preventDefault()
        setPages(pages => pages + 1,)
    };

    const incrementCharts = (event) =>{
        event.preventDefault()
        setCharts(charts => charts + 1,)
    };

    const incrementSlides = (event) =>{
        event.preventDefault()
        setSlides(slides => slides + 1,)
    };

    const incrementReferences = (event) =>{
        event.preventDefault()
        setReferences(references => references + 1,)
    };

    const decrementPages = (event) =>{
        event.preventDefault()
        setPages(pages => Math.max(pages - 1, 0))
    };

    const decrementCharts = (event) =>{
        event.preventDefault()
        setCharts(charts => Math.max(charts - 1, 0))
    };

    const decrementSlides = (event) =>{
        event.preventDefault()
        setSlides(slides => Math.max(slides - 1, 0))
    };

    const decrementReferences = (event) =>{
        event.preventDefault()
        setReferences(references => Math.max(references - 1, 0))
    };

    // Summary display

    let pageStyle, chartStyle, slideStyle, paperLevelStyle, categoryStyle, softwareStyle, writingStyle;

    if (pages < 1) {
        pageStyle = {
            display: 'None'
        }
    }

    if (slides < 1) {
        slideStyle = {
            display: 'None'
        }
    }

    if (charts < 1) {
        chartStyle = {
            display: 'None'
        }
    }

    if (details.paper_level === 'Basic' || details.order_type === 'Programming' || details.paper_level === '' || details.order_type === 'Calculations'){
        paperLevelStyle = {
            display: 'None'
        }
    }

    if (details.order_type === 'Writing' || details.order_type === 'Calculations Assignment'){
        categoryStyle = {
            display: 'none',
            marginLeft: '30px'
        }
    }

    if (details.order_type === 'Writing' || details.order_type === 'Programming Assignment'){
        softwareStyle = {
            display: 'none',
            marginLeft: '30px'
        }
    }

    if (details.order_type === 'Programming Assignment' || details.order_type === 'Calculations Assignment'){
        writingStyle = {
            display: 'none',
            marginLeft: '30px'
        }
    }

    // Price Calculations

    const [level2, setLevel2] = useState('High School');

    const handleChange = (e) => {
        setDetails(details => ({
            ...details, academic_year: e.target.value
        }))
        setLevel2(e.target.value)
    };

    const handleSpacing = (e) => {
        setDetails(prevState => ({
            ...prevState, spacing: e.target.value
        }))
        setSpacing(e.target.value)
    };

    let no_words;

    if(spacing === 'Single'){
        no_words = 550
    }else if(spacing === 'Double'){
        no_words = 275
    };

    let spacing_value;

// PRICES FOR ORDERS ON A HIGH SCHOOL PAPER 
    function pageSwitch(){
        if (level2 === 'High School'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (14.99 * 2).toFixed(2)
                        return (pages * 14.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (14.99).toFixed(2)
                        return (pages * 14.99).toFixed(2)
                    }
                    break;
                case details.deadline === '8 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (13.99 * 2).toFixed(2)
                        return (pages * 13.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (13.99).toFixed(2)
                        return (pages * 13.99).toFixed(2)
                    }
                    break;
                case details.deadline === '24 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (12.99 * 2).toFixed(2)
                        return (pages * 12.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (12.99).toFixed(2)
                        return (pages * 12.99).toFixed(2)
                    }
                    break;
                case details.deadline === '2 Days':
                    if(spacing === 'Single'){
                        spacing_value = (11.99 * 2).toFixed(2)
                        return (pages * 11.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (11.99).toFixed(2)
                        return (pages * 11.99).toFixed(2)
                    }
                    break;
                case details.deadline === '3 Days':
                    if(spacing === 'Single'){
                        spacing_value = (10.99 * 2).toFixed(2)
                        return (pages * 10.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (10.99).toFixed(2)
                        return (pages * 10.99).toFixed(2)
                    }
                    break;
                case details.deadline === '5 Days':
                    if(spacing === 'Single'){
                        spacing_value = (10.99 * 2).toFixed(2)
                        return (pages * 10.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (10.99).toFixed(2)
                        return (pages * 10.99).toFixed(2)
                    }
                    break;
                case details.deadline === '7 Days':
                    if(spacing === 'Single'){
                        spacing_value = (9.99 * 2).toFixed(2)
                        return (pages * 9.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (9.99).toFixed(2)
                        return (pages * 9.99).toFixed(2)
                    }
                    break;
                case details.deadline === '14 Days':
                    if(spacing === 'Single'){
                        spacing_value = (8.99 * 2).toFixed(2)
                        return (pages * 8.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (8.99).toFixed(2)
                        return (pages * 8.99).toFixed(2)
                    }
                    break;
                default:
                    return 0
            }
// PRICES FOR ORDERS ON AN UNDERGRAGUATE YEAR 1-2 PAPER 

        } else if (level2 === 'Undergraduate (years 1-2)'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (15.99 * 2).toFixed(2)
                        return (pages * 15.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (15.99).toFixed(2)
                        return (pages * 15.99).toFixed(2)
                    }
                    break;
                case details.deadline === '8 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (14.99 * 2).toFixed(2)
                        return (pages * 14.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (14.99).toFixed(2)
                        return (pages * 14.99).toFixed(2)
                    }
                    break;
                case details.deadline === '24 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (13.99 * 2).toFixed(2)
                        return (pages * 13.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (13.99).toFixed(2)
                        return (pages * 13.99).toFixed(2)
                    }
                    break;
                case details.deadline === '2 Days':
                    if(spacing === 'Single'){
                        spacing_value = (12.99 * 2).toFixed(2)
                        return (pages * 12.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (12.99).toFixed(2)
                        return (pages * 12.99).toFixed(2)
                    }
                    break;
                case details.deadline === '3 Days':
                    if(spacing === 'Single'){
                        spacing_value = (12.99 * 2).toFixed(2)
                        return (pages * 12.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (12.99).toFixed(2)
                        return (pages * 12.99).toFixed(2)
                    }
                    break;
                case details.deadline === '5 Days':
                    if(spacing === 'Single'){
                        spacing_value = (11.99 * 2).toFixed(2)
                        return (pages * 11.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (11.99).toFixed(2)
                        return (pages * 11.99).toFixed(2)
                    }
                    break;
                case details.deadline === '7 Days':
                    if(spacing === 'Single'){
                        spacing_value = (10.99 * 2).toFixed(2)
                        return (pages * 10.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (10.99).toFixed(2)
                        return (pages * 10.99).toFixed(2)
                    }
                    break;
                case details.deadline === '14 Days':
                    if(spacing === 'Single'){
                        spacing_value = (9.99 * 2).toFixed(2)
                        return (pages * 9.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (9.99).toFixed(2)
                        return (pages * 9.99).toFixed(2)
                    }
                    break;
                default:
                    return 0
            }
// PRICES FOR ORDERS ON AN UNDERGRAGUATE YEAR 3-4 PAPER 

        } else if (level2 === 'Undergraduate (years 3-4)'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (16.99 * 2).toFixed(2)
                        return (pages * 16.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (16.99).toFixed(2)
                        return (pages * 16.99).toFixed(2)
                    }
                    break;
                case details.deadline === '8 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (15.99 * 2).toFixed(2)
                        return (pages * 15.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (15.99).toFixed(2)
                        return (pages * 15.99).toFixed(2)
                    }
                    break;
                case details.deadline === '24 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (14.99 * 2).toFixed(2)
                        return (pages * 14.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (14.99).toFixed(2)
                        return (pages * 14.99).toFixed(2)
                    }
                    break;
                case details.deadline === '2 Days':
                    if(spacing === 'Single'){
                        spacing_value = (13.99 * 2).toFixed(2)
                        return (pages * 13.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (13.99).toFixed(2)
                        return (pages * 13.99).toFixed(2)
                    }
                    break;
                case details.deadline === '3 Days':
                    if(spacing === 'Single'){
                        spacing_value = (13.99 * 2).toFixed(2)
                        return (pages * 13.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (13.99).toFixed(2)
                        return (pages * 13.99).toFixed(2)
                    }
                    break;
                case details.deadline === '5 Days':
                    if(spacing === 'Single'){
                        spacing_value = (12.99 * 2).toFixed(2)
                        return (pages * 12.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (12.99).toFixed(2)
                        return (pages * 12.99).toFixed(2)
                    }
                    break;
                case details.deadline === '7 Days':
                    if(spacing === 'Single'){
                        spacing_value = (11.99 * 2).toFixed(2)
                        return (pages * 11.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (11.99).toFixed(2)
                        return (pages * 11.99).toFixed(2)
                    }
                    break;
                case details.deadline === '14 Days':
                    if(spacing === 'Single'){
                        spacing_value = (10.99 * 2).toFixed(2)
                        return (pages * 10.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (10.99).toFixed(2)
                        return (pages * 10.99).toFixed(2)
                    }
                    break;
                default:
                    return 0
            }

            // PRICES FOR ORDERS ON A GRADUATE PAPER 

        } else if (level2 === 'Graduate'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (17.99 * 2).toFixed(2)
                        return (pages * 17.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (17.99).toFixed(2)
                        return (pages * 17.99).toFixed(2)
                    }
                    break;
                case details.deadline === '8 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (16.99 * 2).toFixed(2)
                        return (pages * 16.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (16.99).toFixed(2)
                        return (pages * 16.99).toFixed(2)
                    }
                    break;
                case details.deadline === '24 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (15.99 * 2).toFixed(2)
                        return (pages * 15.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (15.99).toFixed(2)
                        return (pages * 15.99).toFixed(2)
                    }
                    break;
                case details.deadline === '2 Days':
                    if(spacing === 'Single'){
                        spacing_value = (14.99 * 2).toFixed(2)
                        return (pages * 14.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (14.99).toFixed(2)
                        return (pages * 14.99).toFixed(2)
                    }
                    break;
                case details.deadline === '3 Days':
                    if(spacing === 'Single'){
                        spacing_value = (14.99 * 2).toFixed(2)
                        return (pages * 14.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (14.99).toFixed(2)
                        return (pages * 14.99).toFixed(2)
                    }
                    break;
                case details.deadline === '5 Days':
                    if(spacing === 'Single'){
                        return (pages * 13.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        return (pages * 13.99).toFixed(2)
                    }
                    break;
                case details.deadline === '7 Days':
                    if(spacing === 'Single'){
                        spacing_value = (12.99 * 2).toFixed(2)
                        return (pages * 12.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (12.99).toFixed(2)
                        return (pages * 12.99).toFixed(2)
                    }
                    break;
                case details.deadline === '14 Days':
                    if(spacing === 'Single'){
                        spacing_value = (11.99 * 2).toFixed(2)
                        return (pages * 11.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (11.99).toFixed(2)
                        return (pages * 11.99).toFixed(2)
                    }
                    break;
                default:
                    return 0
            }
// PRICES FOR ORDERS ON A PHD PAPER 

        } else if (level2 === 'PhD'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (18.99 * 2).toFixed(2)
                        return (pages * 18.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (18.99).toFixed(2)
                        return (pages * 18.99).toFixed(2)
                    }
                    break;
                case details.deadline === '8 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (17.99 * 2).toFixed(2)
                        return (pages * 17.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (17.99).toFixed(2)
                        return (pages * 17.99).toFixed(2)
                    }
                    break;
                case details.deadline === '24 Hours':
                    if(spacing === 'Single'){
                        spacing_value = (16.99 * 2).toFixed(2)
                        return (pages * 16.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (16.99).toFixed(2)
                        return (pages * 16.99).toFixed(2)
                    }
                    break;
                case details.deadline === '2 Days':
                    if(spacing === 'Single'){
                        spacing_value = (15.99 * 2).toFixed(2)
                        return (pages * 15.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (15.99).toFixed(2)
                        return (pages * 15.99).toFixed(2)
                    }
                    break;
                case details.deadline === '3 Days':
                    if(spacing === 'Single'){
                        spacing_value = (15.99 * 2).toFixed(2)
                        return (pages * 15.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (15.99).toFixed(2)
                        return (pages * 15.99).toFixed(2)
                    }
                    break;
                case details.deadline === '5 Days':
                    if(spacing === 'Single'){
                        spacing_value = (14.99 * 2).toFixed(2)
                        return (pages * 14.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (14.99).toFixed(2)
                        return (pages * 14.99).toFixed(2)
                    }
                    break;
                case details.deadline === '7 Days':
                    if(spacing === 'Single'){
                        spacing_value = (13.99 * 2).toFixed(2)
                        return (pages * 13.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (13.99).toFixed(2)
                        return (pages * 13.99).toFixed(2)
                    }
                    break;
                case details.deadline === '14 Days':
                    if(spacing === 'Single'){
                        spacing_value = (12.99 * 2).toFixed(2)
                        return (pages * 12.99 * 2).toFixed(2)
                    }else if(spacing === 'Double'){
                        spacing_value = (12.99).toFixed(2)
                        return (pages * 12.99).toFixed(2)
                    }
                    break;
                default:
                    return 0
            }
        }
    }

    function chartSwitch(){
        if (level2 === 'High School'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'Undergraduate (years 1-2)'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'Undergraduate (years 3-4)'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'Graduate'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'PhD'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }
    }

    function slideSwitch(){
        if (level2 === 'High School'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 10/2).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'Undergraduate (years 1-2)'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 12/2).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'Undergraduate (years 3-4)'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 14/2).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'Graduate'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 16/2).toFixed(2)
                default:
                    return 0
            }
        } else if (level2 === 'PhD'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 18/2).toFixed(2)
                default:
                    return 0
            }
        }
    }

    // WRITERS PREFERENCES PRICES
    function pagepreferencePrice(){
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (10.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (10.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (10.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (10.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (10.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (10.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (10.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (10.99).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (12.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (12.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (12.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (12.99).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (12.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (12.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (12.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (12.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (12.99).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (14.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (14.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (14.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (14.99).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (14.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (14.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (14.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (14.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (14.99).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (16.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (16.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (16.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (16.99).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (16.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (16.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (16.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (16.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (16.99).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (18.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (18.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (18.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (18.99).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (18.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (18.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (18.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (18.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (18.99).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (19.99).toFixed(2)
                case details.deadline === '8 Hours':
                    return (19.99).toFixed(2)
                case details.deadline === '24 Hours':
                    return (19.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (19.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (19.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (19.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (19.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (19.99).toFixed(2)
                default:
                    return 0
            }
        }
    }

    function chartpreferencePrice(){
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '8 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '24 Hours':
                    return (charts * 1).toFixed(2)
                case details.deadline === '2 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '3 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '5 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '7 Days':
                    return (charts * 1).toFixed(2)
                case details.deadline === '14 Days':
                    return (charts * 1).toFixed(2)
                default:
                    return 0
            }
        }
    }

    // ON THE CASE WHERE SELECTION CHOICE OF THE CLIENT ON THE SLIDES IS STANDARD
    function slidepreferencePrice(){
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 10/2).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'High School'){
                case details.deadline === '4 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 10/2).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 10/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 10/2).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Undergraduate (years 1-2)'){
                case details.deadline === '4 Hours':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 12/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 12/2).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 14/2).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Undergarduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Undergraduate (years 3-4)'){
                case details.deadline === '4 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 14/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 14/2).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 16/2).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'Graduate'){
                case details.deadline === '4 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 16/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 16/2).toFixed(2)
                default:
                    return 0
            }
        }
        if (details.paper_level === 'Standard'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 18/2).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Basic'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '8 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '24 Hours':
                    return (0.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '7 Days':
                    return (0.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (0.00).toFixed(2)
                default:
                    return 0
            }
        } else if (details.paper_level === 'Advanced'){
            switch(details.academic_year === 'PhD'){
                case details.deadline === '4 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '8 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '24 Hours':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '2 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '3 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '5 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '7 Days':
                    return (slides * 18/2).toFixed(2)
                case details.deadline === '14 Days':
                    return (slides * 18/2).toFixed(2)
                default:
                    return 0
            }
        }
    }

    function programmingPricing(){
        if (details.task_size === 'Extra small'){
            switch(details.task_size === 'Extra small'){
                case details.deadline === '24 Hours':
                    return (45).toFixed(2)
                case details.deadline === '2 Days':
                    return (39).toFixed(2)
                case details.deadline === '3 Days':
                    return (34.50).toFixed(2)
                case details.deadline === '5 Days':
                    return (31.50).toFixed(2)
                case details.deadline === '7 Days':
                    return (30).toFixed(2)
                case details.deadline === '14 Days':
                    return (28.50).toFixed(2)
                default:
                    return 0
            }
        }else if(details.task_size === 'Small'){
            switch(details.task_size === 'Small'){
                case details.deadline === '24 Hours':
                    return (60.0).toFixed(2)
                case details.deadline === '2 Days':
                    return (54.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (54.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (54.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (54.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (50.00).toFixed(2)
                default:
                    return 0
            }
        }else if (details.task_size === 'Medium'){
            switch(details.task_size === 'Medium'){
                case details.deadline === '24 Hours':
                    return (149.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (129.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (109.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (99.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (99.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (99.99).toFixed(2)
                default:
                    return 0
            }
        }else if (details.task_size === 'Large'){
            switch(details.task_size === 'Large'){
                case details.deadline === '24 Hours':
                    return (199.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (179.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (179.99).toFixed(2)
                case details.deadline === '5 Days':
                    return (179.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (179.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (179.99).toFixed(2)
                default:
                    return 0
            }
        }
    }

    function calculationsPricing(){
        if (details.task_size === 'Extra small'){
            switch(details.task_size === 'Extra small'){
                case details.deadline === '24 Hours':
                    return (37.50).toFixed(2)
                case details.deadline === '2 Days':
                    return (32.50).toFixed(2)
                case details.deadline === '3 Days':
                    return (28.75).toFixed(2)
                case details.deadline === '5 Days':
                    return (26.25).toFixed(2)
                case details.deadline === '7 Days':
                    return (25.00).toFixed(2)
                case details.deadline === '14 Days':
                    return (23.75).toFixed(2)
                default:
                    return 0
            }
        }else if(details.task_size === 'Small'){
            switch(details.task_size === 'Small'){
                case details.deadline === '24 Hours':
                    return (60.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (60.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (60.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (57.75).toFixed(2)
                case details.deadline === '7 Days':
                    return (57.75).toFixed(2)
                case details.deadline === '14 Days':
                    return (55.25).toFixed(2)
                default:
                    return 0
            }
        }else if (details.task_size === 'Medium'){
            switch(details.task_size === 'Medium'){
                case details.deadline === '24 Hours':
                    return (99.00).toFixed(2)
                case details.deadline === '2 Days':
                    return (99.00).toFixed(2)
                case details.deadline === '3 Days':
                    return (99.00).toFixed(2)
                case details.deadline === '5 Days':
                    return (80.99).toFixed(2)
                case details.deadline === '7 Days':
                    return (80.99).toFixed(2)
                case details.deadline === '14 Days':
                    return (80.99).toFixed(2)
                default:
                    return 0
            }
        }else if (details.task_size === 'Large'){
            switch(details.task_size === 'Large'){
                case details.deadline === '24 Hours':
                    return (249.99).toFixed(2)
                case details.deadline === '2 Days':
                    return (249.99).toFixed(2)
                case details.deadline === '3 Days':
                    return (230).toFixed(2)
                case details.deadline === '5 Days':
                    return (210).toFixed(2)
                case details.deadline === '7 Days':
                    return (200).toFixed(2)
                case details.deadline === '14 Days':
                    return (190).toFixed(2)
                default:
                    return 0
            }
        }
    }

    function totalpreferencePrice(){

        let pagepreference = pagepreferencePrice()
        let chartpreference = chartpreferencePrice()
        let slidepreference = slidepreferencePrice()

        if(pages && charts && slides){
            return (parseFloat(pagepreference) + parseFloat(chartpreference) + parseFloat(slidepreference)).toFixed(2)
        }else if (pages && charts){
            return (parseFloat(pagepreference) + parseFloat(chartpreference)).toFixed(2)
        } else if(pages && slides){
            return (parseFloat(pagepreference) + parseFloat(slidepreference)).toFixed(2)
        } else if(charts && slides){
            return (parseFloat(chartpreference) + parseFloat(slidepreference)).toFixed(2)
        } else if(pages){
            return pagepreference
        } else if(charts){
            return chartpreference
        } else if (slides){
            return slidepreference
        }
    }

    function totalPrice(){
        let pagePrice = pageSwitch()
        let chartPrice = chartSwitch()
        let slidePrice = slideSwitch()
        let totalpreference = totalpreferencePrice()
        let totalprog = programmingPricing()
        let totalcalc = calculationsPricing()
        
        let totalPrice;

        if (details.order_type === 'Writing'){
            totalPrice = parseFloat(pagePrice) + parseFloat(chartPrice) + parseFloat(slidePrice) + parseFloat(totalpreference)
            
            if (discount > 0) {
                let discountedPrice = totalPrice - discount
                return discountedPrice.toFixed(2)
            }else {
                return totalPrice.toFixed(2)
            }

        }else if(details.order_type === 'Programming Assignment'){
            totalPrice = parseFloat(totalprog)
           
            if (discount > 0) {
                let discountedPrice = totalPrice - discount
                return discountedPrice.toFixed(2)
            }else {
                return totalPrice.toFixed(2)
            }

        }else if(details.order_type === 'Calculations Assignment'){
            totalPrice = parseFloat(totalcalc)

            if (discount > 0) {
                let discountedPrice = totalPrice - discount
                return discountedPrice.toFixed(2)
            }else {
                return totalPrice.toFixed(2)
            }
        }
    }

    let price = totalPrice()

    useEffect(() => {
        if (price > 0){
            setDetails(prevState => ({
                ...prevState, amount: price
            }))
        }
    }, [price]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (token === null){
            alert('You are not logged in')
            return navigate(`/log`, {replace: true})
        }

        const uploadData = new FormData();

        if (details.instruction_file){
            uploadData.append('pages', details.pages)
            uploadData.append('charts', details.charts)
            uploadData.append('slides', details.slides)
            uploadData.append('paper_type', details.paper_type)

            details.instruction_file.forEach((file, index) => {
                uploadData.append(`instruction_file${index}`, file, file.name);
            })

            uploadData.append('subject', details.subject)
            uploadData.append('instructions', details.instructions)
            uploadData.append('paper_format', details.paper_format)
            uploadData.append('references', details.references)
            uploadData.append('order_type', details.order_type)
            uploadData.append('academic_year', details.academic_year)
            uploadData.append('title', details.title)
            uploadData.append('deadline', details.deadline)
            uploadData.append('paper_level', details.paper_level)
            uploadData.append('upgrade', details.upgrade)
            uploadData.append('task_size', details.task_size)
            uploadData.append('programming_category', details.programming_category)
            uploadData.append('prog_language', details.prog_language)
            uploadData.append('software', details.software)
            uploadData.append('discipline', details.discipline)
            uploadData.append('spacing', details.spacing)
            uploadData.append('amount', details.amount)
        }else{
            uploadData.append('pages', details.pages)
            uploadData.append('charts', details.charts)
            uploadData.append('slides', details.slides)
            uploadData.append('paper_type', details.paper_type)
            uploadData.append('subject', details.subject)
            uploadData.append('instructions', details.instructions)
            uploadData.append('paper_format', details.paper_format)
            uploadData.append('references', details.references)
            uploadData.append('order_type', details.order_type)
            uploadData.append('academic_year', details.academic_year)
            uploadData.append('title', details.title)
            uploadData.append('deadline', details.deadline)
            uploadData.append('paper_level', details.paper_level)
            uploadData.append('upgrade', details.upgrade)
            uploadData.append('task_size', details.task_size)
            uploadData.append('programming_category', details.programming_category)
            uploadData.append('prog_language', details.prog_language)
            uploadData.append('software', details.software)
            uploadData.append('discipline', details.discipline)
            uploadData.append('spacing', details.spacing)
            uploadData.append('amount', details.amount)
        }

        fetch('https://tothemoonexperts-api.herokuapp.com/orders/summary', {
            method: 'POST',
            headers: {  
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: uploadData
        }).then(
            data => data.json()
        ).then(
            res => setref(res.id)
        ).then(
            () => setRedirect(!redirect)
        ).catch(err => console.log(err))
    };

    function handleCoupon(e){
        e.preventDefault()
        if (token === null){
            alert('You are not logged in')
            return navigate(`/log`, {replace: true})
        }
        setLoading(true);

        fetch('https://tothemoonexperts-api.herokuapp.com/card/promo-code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({"promo_code": promoCode})
        }).then(
            res => {
                res.json().then(
                    data => {
                        if (data.status === 'valid') {
                            setDiscount(parseFloat(data.value))
                            setText(data.message)
                            setLoading(false);
                        }
                        else if (data.status === 'invalid') {
                            setText(data.message)
                            setLoading(false);
                        }
                    }
                )
            }
        ).catch(err => console.log(err))
    };

    if (redirect) {
        return navigate(`/order/pay/${ref}`, {replace: true})
    }

    let errorStyle = {
        fontSize: '.9rem',
        color: '#D2122E',
    }

    const handleErrors = () => {

        let order_typeError = ''
        let paper_typeError = ''
        let academic_yearError = ''
        let deadlineError = ''
        let spacingError = ''
        let disciplineError = ''
        let chartsSlidesPagesError = ''

        if (orderType === 'Writing') {
            if (details.order_type.length < 1){
                order_typeError = 'Select order type'
            }else if (details.order_type.length > 0){
                order_typeError = ''
            }
            if (details.paper_type.length < 1){
                paper_typeError = 'Select paper type'
            }else if (details.paper_type.length > 0){
                paper_typeError = ''
            }
            if (details.discipline.length < 1){
                disciplineError = 'Select discipline'
            }else if (details.discipline.length > 0){
                disciplineError = ''
            }
            if (details.academic_year.length < 1){
                academic_yearError = 'Select academic level'
            }else{
                academic_yearError = ''
            }
            if (details.deadline.length < 1){
                deadlineError = 'Select deadline for delivery'
            }else{
                deadlineError = ''
            }
            if (pages < 1 && charts < 1 && slides < 1){
                chartsSlidesPagesError = 'Cannot be zero'
            }
            if (details.spacing.length < 1){
                spacingError = 'Select spacing'
            }else{
                spacingError = ''
            }
            if (order_typeError || paper_typeError || deadlineError || spacingError || academic_yearError || disciplineError || chartsSlidesPagesError){

                setValidators({order_typeError, paper_typeError, deadlineError, spacingError, academic_yearError, disciplineError, chartsSlidesPagesError}) 
                setError(true)
                return false
            }
            setError(false)
            return true
        }

        return true
    };

    const validate = () => {
        const isValid = handleErrors()
        if(isValid){
            setCurStep('SecondStep')
        }else{
            setCurStep('FirstStep')
        }
    };

    let displayStep, orderbtn, prevbtn;

    if (curStep === 'FirstStep'){
        displayStep = (
            <div className="place-order-requirements-container">
                <h1>Create a New Order</h1>
                <div className="place-order-requirements">
                <form>
                    <div className="top">
                        <div className="assignment-type">
                            <h4 title="Choose whether the task requires 'writing', a programming task (code and report) or if its a calculation assignment. This will help us choose the right expert for the work ">Assignment type:</h4>
                            <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                <Form.Group controlId="formGridState">
                                    <Form.Select defaultValue={details.order_type.length > 1 ? details.order_type : "Writing"} className='select' onChange={(e) => {
                                        setOrderType(e.target.value)
                                        setDetails(prevState => ({
                                            ...prevState, order_type:e.target.value
                                        }))
                                    }}>
                                    <option value="Writing">Writing</option>
                                    <option value="Programming Assignment">Programming Assignment</option>
                                    <option value="Calculations Assignment">Calculations Assignment</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <h5 style={errorStyle}>{validators.order_typeError}</h5>
                        </div>
                        <div className="assignment-type">
                            {orderType === 'Writing' && 
                                <>
                                    <h4 title="Example - I need a research paper (choose option research paper), I need a paper review (choose option paper review)">Type of Paper:</h4>
                                    <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                        <Form.Group controlId="formGridState">
                                            <Form.Select defaultValue={details.paper_type.length > 1 ? details.paper_type : "Essay"} className='select' onChange={(e) => setDetails(prevState => ({
                                                ...prevState, paper_type: e.target.value
                                            }))}>
                                            <option value="Essay (Any Type)">Essay (Any Type)</option>
                                            <option value="SEO Articles">SEO Articles</option>
                                            <option value="Scholarship Essay">Scholarship Essay</option>
                                            <option value="Entrance Essay">Entrance Essay</option>
                                            <option value="Research Paper">Research Paper</option>
                                            <option value="Paper Review">Paper Review</option>
                                            <option value="Discussion Post">Discussion Post</option>
                                            <option value="Term Paper">Term Paper</option>
                                            <option value="Business Plan">Business Plan</option>
                                            <option value="Business Proposal">Business Proposal</option>
                                            <option value="Course Work">Course Work</option>
                                            <option value="Creative Writing">Creative Writing</option>
                                            <option value="Case Study">Case Study</option>
                                            <option value="Capstone Project">Capstone Project</option>
                                            <option value="Lab Report">Lab Report</option>
                                            <option value="Literature Review">Literature Review</option>
                                            <option value="Paper Outline">Paper Outline</option>
                                            <option value="Personal Narrative">Personal Narrative</option>
                                            <option value="Produt Description">Product Description</option>
                                            <option value="Reflective Writing">Reflective Writing</option>
                                            <option value="Newsletters">Newsletters</option>
                                            <option value="Web Content"> Web Content</option>
                                            <option value="Report">Report</option>
                                            <option value="Research Proposal">Research Proposal</option>
                                            <option value="Thesis/Dissertation">Thesis/Dissertation</option>
                                            <option value="PowerPoint Presentation">PowerPoint Presentation</option>
                                            <option value="Question-Answer">Question-Answer</option>
                                            <option value="Other Work">Other work</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <h5 style={errorStyle}>{validators.paper_typeError}</h5>
                                </>
                            }
                            {orderType === 'Programming Assignment' &&
                                <>
                                    <h4>Programming Language:</h4>
                                    <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                        <Form.Group controlId="formGridState">
                                            <Form.Select defaultValue={details.prog_language.length > 1 ? details.prog_language : "Python"} className='select' onChange={(e) => setDetails(prevState => ({
                                                ...prevState, prog_language: e.target.value
                                            }))}>
                                            <option value="Python">Python</option>
                                            <option value="JavaScript">JavaScript</option>
                                            <option value="C">C</option>
                                            <option value="C++">C++</option>
                                            <option value="Java">Java</option>
                                            <option value="C#">C#</option>
                                            <option value="SQL">SQL</option>
                                            <option value="R/R Studio">R/R Studio</option>
                                            <option value="Matlab">Matlab</option>
                                            <option value="Excel">Excel</option>
                                            <option value="HTML">HTML</option>
                                            <option value="CCNA">CCNA (Networking)</option>
                                            <option value="Others">Others</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <h5 style={errorStyle}>{validators.paper_typeError}</h5>
                                </>
                            }
                            {orderType === 'Calculations Assignment' &&
                                <>
                                    <h4>Subject:</h4>
                                    <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                        <Form.Group controlId="formGridState">
                                            <Form.Select defaultValue={details.subject.length > 1 ? details.subject : "Engineering"} className='select' onChange={(e) => setDetails(prevState => ({
                                                ...prevState, subject: e.target.value
                                            }))}>
                                            <option value="Engineering">Engineering</option>
                                            <option value="Aviation">Aviation</option>
                                            <option value="Mathematics & Finance">Mathematics & Finance</option>
                                            <option value="Chemistry">Chemistry</option>
                                            <option value="Statistics">Statistics</option>
                                            <option value="Geography">Geography</option>
                                            <option value="Biology">Biology</option>
                                            <option value="Business">Business</option>
                                            <option value="Medicine & Nursing">Medicine & Nursing</option>
                                            <option value="Other">Other</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <h5 style={errorStyle}>{validators.paper_typeError}</h5>
                                </>
                            }
                        </div>
                    </div>

                    <div className="mid">
                        <div className="assignment-details">
                            <h4 title="If you cant figure out your category, choose 'Any Category', otherwise, choose the suitable category ">Assignment details (Category, and academic level):</h4>
                            {orderType === 'Writing' &&
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.discipline.length > 1 ? details.discipline : "Philosophy"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, discipline: e.target.value
                                        }))}>
                                        <option value="Any Category">Any Category</option>
                                        <option value="Mathematics">Mathematics</option>
                                        <option value="Business">Business</option>
                                        <option value="Chemistry">Chemistry</option>
                                        <option value="Physics">Physics</option>
                                        <option value="English">English</option>
                                        <option value="Biology">Biology</option>
                                        <option value="Computer Science">Computer Science</option>
                                        <option value="Information Technology">Information Technology</option>
                                        <option value="Cybersecurity">Cybersecurity</option>
                                        <option value="Databases">Databases</option>
                                        <option value="Programming">Programming</option>
                                        <option value="English Literature">English Literature</option>
                                        <option value="Poetry">Poetry</option>
                                        <option value="Art (Film and Perfoming Art)">Art (Film and Perfoming Art)</option>
                                        <option value="Economics">Economics</option>
                                        <option value="Political Science">Political Science</option>
                                        <option value="Psychology">Psychology</option>
                                        <option value="Tourism">Tourism</option>
                                        <option value="Accounting">Accounting</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="International Relations">International Relations</option>
                                        <option value="Statistics">Statistics</option>
                                        <option value="Education">Education</option>
                                        <option value="Medicine">Medicine</option>
                                        <option value="Law">Law</option>
                                        <option value="Sports">Sports</option>
                                        <option value="Others">Others</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                            {orderType === 'Programming Assignment' &&
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.programming_category.length > 1 ? details.programming_category : "Software Development"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, programming_category: e.target.value
                                        }))}>
                                        <option value="Web Programming">Web Programming</option>
                                        <option value="General Programming">General Programming</option>
                                        <option value="Software Development">Software Development</option>
                                        <option value="Database Design">Database Design</option>
                                        <option value="App Development">App Development</option>
                                        <option value="Other">Other</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                            {orderType === 'Calculations Assignment' &&
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.software.length > 1 ? details.software : "Microsoft Excel"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, software: e.target.value
                                        }))}>
                                        <option value="Microsoft Excel">Microsoft Excel</option>
                                        <option value="Microsoft Word">Microsoft Word</option>
                                        <option value="STATA">STATA</option>
                                        <option value="SPSS">SPSS</option>
                                        <option value="Other">Other</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                            <h5 style={errorStyle}>{validators.disciplineError}</h5>
                            {orderType === 'Writing' &&
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.academic_year.length > 1 ? details.academic_year : "High School"} className='select' onChange={handleChange}>
                                        <option value="High School">High School</option>
                                        <option value="Undergraduate (years 1-2)">Undergarduate (years 1-2)</option>
                                        <option value="Undergraduate (years 3-4)">Undergarduate (years 3-4)</option>
                                        <option value="Graduate">Graduate (Masters)</option>
                                        <option value="PhD">PhD</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                             
                            {orderType === 'Programming Assignment' &&
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.task_size.length > 1 ? details.task_size : "Extra Small"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, task_size: e.target.value
                                        }))}>
                                          
                                        <option value="Extra small" title=" Upto 3 short questions">Extra small</option>
                                        <option value="Small" title=" Upto 5 questions and a simple program with up to 7 functions ">Small</option>
                                        <option value="Medium" title=" This is a well defined project - Upto 10 problems, a small report explaining the problems">Medium</option>
                                        <option value="Large" title=" Large programming task or project - It requires an extensive lab report">Large</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                            {orderType === 'Calculations Assignment' &&
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.task_size.length > 1 ? details.task_size : "Extra Small"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, task_size: e.target.value
                                        }))}>
                                        <option value="Extra small" title=" Not more than 3 short practice problems or theoretical questions. Possible deliverables include Simple computations and Short answers to questions">Extra small</option>
                                        <option value="Small" title="Not more than 7 short practice problems or theoretical questions. Possible deliverables include Simple computations and Short answers to questions">Small</option>
                                        <option value="Medium" title="Not more than 15 problems or theoretical questions. Possible deliverables include Computations Models, drawings and charts, and a report if needed">Medium</option>
                                        <option value="Large" title="Moderately complex projects. Possible deliverables include Computations Models, drawings and charts, Documentation, Detailed report, and Presentation">Large</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            }
                            <h5 style={errorStyle}>{validators.academic_yearError}</h5>
                        </div>
                        {orderType === 'Writing' &&
                            <div className="deadline">
                                <h4 title="When do you need the work - for very urgent work - choose 'Very Urgent' ">Deadline:</h4>
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.deadline.length > 1 ? details.deadline : "4 Hours"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, deadline: e.target.value
                                        }))}>
                                        <option value="4 Hours">Very Urgent (4 Hours)</option>
                                        <option value="8 Hours">8 Hours</option>
                                        <option value="24 Hours">24 Hours</option>
                                        <option value="2 Days">2 Days</option>
                                        <option value="3 Days">3 Days</option>
                                        <option value="5 Days">5 Days (Recommended)</option>
                                        <option value="7 Days">7 Days</option>
                                        <option value="14 Days">14 Days</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <h5 style={errorStyle}>{validators.deadlineError}</h5>
                            </div>
                        }
                        {orderType === 'Programming Assignment' &&
                            <div className="deadline">
                                <h4>Deadline:</h4>
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.deadline.length > 1 ? details.deadline : "24 Hours"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, deadline: e.target.value
                                        }))}>
                                        <option value="24 Hours">24 Hours</option>
                                        <option value="2 Days">2 Days</option>
                                        <option value="3 Days">3 Days</option>
                                        <option value="5 Days">5 Days</option>
                                        <option value="7 Days">7 Days</option>
                                        <option value="14 Days">14 Days and more</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <h5 style={errorStyle}>{validators.deadlineError}</h5>
                            </div>
                        }
                        {orderType === 'Calculations Assignment' &&
                            <div className="deadline">
                                <h4>Deadline:</h4>
                                <div className="col-sm-12 col-md-6 mt-3" style={{width: '360px'}}>
                                    <Form.Group controlId="formGridState">
                                        <Form.Select defaultValue={details.deadline.length > 1 ? details.deadline : "24 Hours"} className='select' onChange={(e) => setDetails(prevState => ({
                                            ...prevState, deadline: e.target.value
                                        }))}>
                                        <option value="24 Hours">24 Hours</option>
                                        <option value="2 Days">2 Days</option>
                                        <option value="3 Days">3 Days</option>
                                        <option value="5 Days">5 Days</option>
                                        <option value="7 Days">7 Days</option>
                                        <option value="14 Days">14 Days</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <h5 style={errorStyle}>{validators.deadlineError}</h5>
                            </div>
                        }
                    </div>

                    {orderType === 'Writing' &&
                        <div className="bottom">
                        <div className="size">
                            <h4 title=" How long is the work and should it be single-spaced or double-spaced">Assignment size:</h4>
                            <div className="pages">
                                <div className="page-ref">
                                    <h4 title="For example, if your paper is 10 pages, increase the counter to 10 pages">Pages:</h4>
                                    <div className="ref">
                                        <button onClick={decrementPages} >-</button>
                                        <input type="text" value={pages}/>
                                        <button onClick={incrementPages}>+</button>
                                    </div>
                                    <h5 style={errorStyle}>{validators.chartsSlidesPagesError}</h5>
                                </div>
                                <div className="words">
                                    <h4>Words:</h4>
                                    <button>{no_words}</button>
                                </div>
                                <div className="line-spacing">
                                    <h4 title="For an IEEE paper - choose 'Single-Spacing' ">Line spacing:</h4>
                                    <div className="col-sm-12 col-md-6 mt-3" style={{width: '160px'}}>
                                        <Form.Group controlId="formGridState">
                                            <Form.Select defaultValue={details.spacing.length > 1 ? details.spacing : "Double"} className='select' onChange={handleSpacing}>
                                            <option value="Double">Double-Spacing</option>
                                            <option value="Single">Single-Spacing</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <h5 style={errorStyle}>{validators.spacingError}</h5>
                                </div>
                            </div>
                        </div>

                        <div className="charts-slides">
                            <h4 title="for powerpoint presentation, please fill in the below">Charts, Slides & References</h4>
                            <div className="chart-ref">
                                <div className="page-ref">
                                    <h4>Slides:</h4>
                                    <div className="ref">
                                        <button onClick={decrementSlides} >-</button>
                                        <input type="text" value={slides}/>
                                        <button onClick={incrementSlides}>+</button>
                                    </div>
                                    <h5 style={errorStyle}>{validators.chartsSlidesPagesError}</h5>
                                </div>
                                <div className="page-ref" style={{marginLeft: '20px'}}>
                                    <h4>Charts:</h4>
                                    <div className="ref">
                                        <button onClick={decrementCharts} >-</button>
                                        <input type="text" value={charts}/>
                                        <button onClick={incrementCharts}>+</button>
                                    </div>
                                    <h5 style={errorStyle}>{validators.chartsSlidesPagesError}</h5>
                                </div>
                                <div className="page-ref" style={{marginLeft: '20px'}}>
                                    <h4>References:</h4>
                                    <div className="ref">
                                        <button onClick={decrementReferences} >-</button>
                                        <input type="text" value={references}/>
                                        <button onClick={incrementReferences}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                </form>
                </div>
            </div>
        )

        orderbtn = (
            <div className="next-step">
                <button onClick={validate}>Next Step</button>
            </div>
        )
    }else if (curStep === 'SecondStep'){
        displayStep = (
            <div className="place-order-requirements-container">
                <h1 title=" Fill in the below steps so that we can understand how we can help. You can always call if you need a clarification">Create a New Order</h1>
                <Modal 
                    open={open}
                    onClose={() => setOpen(false)}
                >
                
                    <div className="summary-container">
                        <div className="top">
                            <h2 title="Here is a summary of what you ordered - You can always go back to change specifications of the work">Summary details</h2>
                            <ul>
                                <li style={writingStyle}>{details.paper_type}</li>
                                <li style={categoryStyle}>{details.programming_category}</li>
                                <li style={softwareStyle}>{details.subject}</li>
                                <li style={writingStyle}>{details.discipline}</li>
                                <li style={categoryStyle}>{details.prog_language}</li>
                                <li style={softwareStyle}>{details.software}</li>
                            </ul>
                            <form style={{paddingBottom: text.length > 1 ? '0': '10px'}}>
                                {
                                    loading && <div className='promo-loader'></div>
                                }
                                {text.length > 1 &&
                                    <div className='promo-message'>
                                        <h5>{text}</h5>
                                    </div>
                                }
                                {text.length < 1 &&
                                    <div className='form-binder' style={{display: loading ? 'none' : 'flex'}}>
                                        <input type="text" required
                                            value={promoCode}
                                            onChange={(e) => setPromoCode(e.target.value)}
                                            placeholder='PROMO CODE'
                                        />
                                        <button onClick={handleCoupon}>APPLY</button>
                                    </div>
                                }
                            </form>
                            <ol>
                                <li>{details.order_type}</li>
                                <li>${price}</li>
                            </ol>
                            <h4 style={pageStyle}>{pages} pages x ${spacing_value} .........<span>${pageSwitch()}</span></h4>
                            <h4 style={slideStyle}>{slides} slides   ....................... <span>${slideSwitch()}</span></h4>
                            <h4 style={chartStyle}>{charts} charts   ....................... <span>${chartSwitch()}</span></h4>
                            <h4 style={paperLevelStyle}>Writer's preference <span>${totalpreferencePrice()}</span></h4>
                            <h4>Coupon discount <span>{discount > 0 ? '(-)' : ''}${discount}</span></h4>
                            <ol>
                                <li>Total Price</li>
                                <li>${price}</li>
                            </ol>
                            <button onClick={handleSubmit}>Proceed to Checkout</button>
                        </div>
                    </div>

                </Modal>

                <div className="order-details-container">
                {orderType === 'Writing' &&
                    <div className="top">
                        <div className="title">
                            <h4 title="For example 'I need a paper review, I need a research paper', 'research paper for the topic xyz' ">Title:</h4>
                            <form>
                                <input type='text' 
                                    placeholder="Enter title of your paper"
                                    value={details.title}
                                    onChange={(e) => setDetails(prevState => ({
                                        ...prevState, title: e.target.value
                                    }))}
                                />
                            </form>
                        </div>
                        <div className="format">
                            <h4 title=" Choose the format of your paper: APA, IEEE, MLA? If unapplicable choose 'Others' ">Paper format:</h4>
                            <div className="col-sm-12 col-md-6 mt-3" style={{width: '310px'}}>
                                <Form.Group controlId="formGridState">
                                    <Form.Select defaultValue="MLA" className='select' onChange={(e) => setDetails(prevState => ({
                                        ...prevState, paper_format: e.target.value
                                    }))}>
                                    <option value="MLA">MLA </option>
                                    <option value="APA 6">APA 6 </option>
                                    <option value="APA 7">APA 7 </option>
                                    <option value="Chicago / Turabian">Chicago / Turabian</option>
                                    <option value="IEEE Format">IEEE Format</option>
                                    <option value="Havard">Havard </option>
                                    <option value="Others">Others</option>
                                
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                }

                    <div className="mid">
                        <h4 title="Write any details that you feel the expert should know about the work. If the details are big, provide them as an attachment on the section 'Additional materials' ">Paper details:</h4>
                        <form>
                            <textarea
                                placeholder="Write anything (This can include important instructions or anything you would like to clarify regarding this work)"
                                value={details.instructions}
                                onChange={(e) => setDetails(prevState => ({
                                    ...prevState, instructions: e.target.value
                                }))}
                            ></textarea>
                        </form>
                    </div>

                    <div className="bottom">
                        <div className="files" style={{width: orderType === 'Writing' ? '30%' : '100%'}}>
                            <h4 title=" Attach Any document that is essential for this work">Additional materials (Attachments):</h4>
                            <div className='upload'>
                                <form>
                                    <input type='file'
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                setDetails(prevState => ({
                                                ...prevState, instruction_file: e.target.files[0]
                                                }));
                                            } else {
                                                setDetails(prevState => ({
                                                ...prevState, instruction_file: e.target.value
                                                }));
                                            }
                                        }}
                                    />
                                </form>
                            </div>
                        </div>

                        {orderType === 'Writing' &&
                            <form>
                                <h4 title="We recommmend an 'Advanced Paper' because it includes almost every everything that a top level paper can include ">Choose paper level (Advanced Paper - Recommended) </h4>

                                <div className='level12'>
                                    <div className={details.paper_level === 'Basic' ? "input1 selected" : "input1"}>
                                        <input 
                                            type='radio'
                                            value='Basic'
                                            id='basic'
                                            checked={details.academic_year === 'Basic'}
                                            onChange={(e) => setDetails(details => ({
                                                ...details, paper_level: e.target.value
                                            }))}
                                        />
                                        <label for='basic' style={{ fontWeight: "bold" }} title=" Simple Language - Mostly recommended for international students">Standard Paper</label>
                                        <span style={{color: "#3367d6", fontSize: 14, fontWeight: "bold"}}> {" "} For Everyday Homework</span>
                                    </div>
                                    <div className={details.paper_level === 'Standard' ? "input1 selected" : "input1"}>
                                        <input 
                                            type='radio'
                                            value='Standard'
                                            id='standard'
                                            checked={details.academic_year === 'Standard'}
                                            onChange={(e) => setDetails(details => ({
                                                ...details, paper_level: e.target.value
                                            }))}
                                        />
                                        <label for='standard'style={{ fontWeight: "bold" }} title=" Regular Language - Most of our clients choose this option">Advanced Paper</label>
                                        <span style={{color: "#3367d6", fontSize: 14, fontWeight: "bold"}}> {" "} For Essential Homework</span>
                                    </div>
                                    <div className={details.paper_level === 'Advanced' ? "input1 selected" : "input1"}>
                                        <input 
                                            type='radio'
                                            value='Advanced'
                                            id='advanced'
                                            checked={details.academic_year === 'Advanced'}
                                            onChange={(e) => setDetails(details => ({
                                                ...details, paper_level: e.target.value
                                            }))}
                                        />
                                        <label for='advanced' style={{ fontWeight: "bold" }} title=" Expert Language - Sentences are structured better, grammar is complex and the work is tripple checked by quality assurance team - Best option for final papers">Top-Level Paper </label>
                                        <span style={{color: "#3367d6", fontSize: 14, fontWeight: "bold"}}> {" "} Need Extra attention</span>
                                    
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        )

        orderbtn = (
            <div className="next-step">
                <button onClick={() => setOpen(!open)}>Summary</button>
            </div>
        )

        prevbtn = (
            <div className="prev-step">
                    <button onClick={() => setCurStep('FirstStep')}>Prev Step</button>
            </div>
        )
    }

    return (
        <div className="make-container">
        <div className="placeorder-container">

            <div className="placeorder-sidebar">
                <div className="order-requirements">
                    <div>
                        <h2>1</h2>
                    </div>
                    <ul>
                        <li>
                            <h3 style={{color: error ? '#D2122E' : '#fff'}}>Order requirements</h3>
                            <p style={{color: error ? '#D2122E' : '#fff', fontSize: '13px'}}>Fill order requirements. For clarification, hover over the title.</p>
                        </li>
                    </ul>
                </div>
                <div className="order-requirements">
                    <div>
                        <h2>2</h2>
                    </div>
                    <ul>
                        <li>
                            <h3 style={{color: error ? '#D2122E' : '#fff'}}>Payment</h3>
                            <p style={{color: error ? '#D2122E' : '#fff', fontSize: '13px'}}>Click on proceeed to checkout to make payment.</p>
                        </li>
                    </ul>
                </div>
                <div className="order-requirements">
                    <div>
                        <h2>3</h2>
                    </div>
                    <ul>
                        <li>
                            <h3 style={{color: error ? '#D2122E' : '#fff'}}>Order Completion</h3>
                            <p style={{color: error ? '#D2122E' : '#fff', fontSize: '13px'}}>Order is completed within the specifed duration and instructions.</p>
                        </li>
                    </ul>
                </div>
                <div className="order-requirements">
                    <div>
                        <h2>4</h2>
                    </div>
                    <ul>
                        <li>
                            <h3 style={{color: error ? '#D2122E' : '#fff'}}>Order Delivery</h3>
                            <p style={{color: error ? '#D2122E' : '#fff', fontSize: '13px'}}>Order sent on the dashboard after completion. For any clarification, send us a message or call us.</p>
                        </li>
                    </ul>
                </div>
            </div>

            {displayStep}


        </div>
            <div className="summarybtn">
                <div className="prev-step">
                    {prevbtn}
                </div>

                <div className="next-step">
                    {orderbtn}
                </div>
            </div>
        </div>
    )
}