import React, { useState, useEffect, useRef } from "react";

import BusinessPlan from '../Documents/Business Plan.pdf';
import LiteraturePaperDoc from '../Documents/Literature.pdf';
import DissertationDoc from '../Documents/Dissertation example.pdf';
import PaperReviewDoc from '../Documents/paper review.pdf';
import SocialPaperDoc from '../Documents/Social.pdf';
import TermPaperDoc from '../Documents/term paper.pdf';
import TechnologyPaperDoc from '../Documents/Technology.pdf';
import DocumentationPaperDoc from '../Documents/Documentation example.pdf';

import "./pages.css";

import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Carousel from "react-bootstrap/Carousel";
import about_pic from "../images/about-left.png";
import hero1 from "../images/carousel images/studyingone.jpg";
import hero2 from "../images/carousel images/studyingtwo.jpg";
import hero3 from "../images/carousel images/studyingthree.jpg";
import hero4 from "../images/carousel images/studyingfour.jpg";
import hero15 from "../images/carousel images/studenthelp.jpg";
import hero16 from "../images/carousel images/studenthelp2.jpg";
import hero17 from "../images/carousel images/studenthelp3.jpg";
import hero18 from "../images/carousel images/studenthelp4.jpg";
import hero19 from "../images/carousel images/studenthelp5.jpg";

import testimonial2 from "../images/testimonial-img-2.png";
import testimonial3 from "../images/testimonial-img-5.png";
import team5 from "../images/team-img-5.png";
import team2 from "../images/team-img-2.png";
import team6 from "../images/team-img-6.png";
import team7 from "../images/team-img-7.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";


library.add(faTwitter, faFacebookF, faLinkedinIn, faInstagram);

export default function Home() {
  const [scrolled, setScrolled] = useState(false);
  const [token, setToken] = useState(null);

  // slidenavbar
  const [isExpanded, setIsExpanded] = useState(false);

  // carousel
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const scrollRef = useRef();

  const handleScroll = () => {
    console.log("selem");
    const offset = window.scrollY;

    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    checkToken();
  }, []);

  function checkToken() {
    let is_token = localStorage.getItem("token");
    setToken(is_token);
  }

  let navbarClasses = ["navigation"];

  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  return (
    <div className="home-container">
      <div className={navbarClasses.join(" ")}>
        <div className="header-area bg-white header-sticky only-mobile-sticky">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="header position-relative"> 
                  {/* brand logo */}
                  <div className="header__logo">
                    <Link to={"/"}>
                      <a>
                        <img
                          src="assets/images/logo/scaled2.png"
                          className="img-fluid"
                          alt=""
                          width={160}
                          height={48}
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="header-right flexible-image-slider-wrap">
                    <div
                      className="header-right-inner"
                      id="hidden-icon-wrapper"
                    >
                      {/* Header Search Form */}
                      <div className="header-search-form d-md-none d-block">
                        <form action="#" className="search-form-top">
                          <input
                            className="search-field"
                            type="text"
                            placeholder="Search…"
                          />
                          <button className="search-submit">
                            <i className="search-btn-icon fa fa-search" />
                          </button>
                        </form>
                      </div>
                      {/* Header Top Info */}
                      <div className="swiper-container header-top-info-slider-werap top-info-slider__container">
                        <div className="swiper-wrapper header-top-info-inner">
                          <div className="swiper-slide">
                            <div className="info-item">
                              <div className="info-icon">
                                <span className="fa fa-phone" />
                              </div>
                              <div className="info-content">
                                <h6 className="info-title">+254 728272483</h6>
                                <div className="info-sub-title">
                                  support@tothemoonexperts.com
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="info-item">
                              <div className="info-icon">
                                <span className="fa fa-map-marker-alt" />
                              </div>
                              <div className="info-content">
                                <h6 className="info-title">
                                  219 Amara Fort Apt. 394
                                </h6>
                                <div className="info-sub-title">
                                  New York, NY 941
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="info-item">
                              <div className="info-icon">
                                <span className="fa fa-clock" />
                              </div>
                              <div className="info-content">
                                <h6 className="info-title">8:00AM - 8:00AM</h6>
                                <div className="info-sub-title">
                                  Monday to Sunday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="info-item">
                              <div className="info-icon">
                                <span className="fa fa-comment-alt-lines" />
                              </div>
                              <div className="info-content">
                                <h6 className="info-title">Online 24/7</h6>
                                <div className="info-sub-title">
                                  +254 726071810
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Header Social Networks */}
                      <div className="header-social-networks style-icons">
                        <div className="inner">
                          <a
                            className=" social-link hint--bounce hint--bottom-left"
                            aria-label="Twitter"
                            href="https://twitter.com"
                            data-hover="Twitter"
                            target="_blank"
                          >
                            <i className="social-icon fab fa-twitter" />
                          </a>
                          <a
                            className=" social-link hint--bounce hint--bottom-left"
                            aria-label="Facebook"
                            href="https://facebook.com"
                            data-hover="Facebook"
                            target="_blank"
                          >
                            <i className="social-icon fab fa-facebook-f" />
                          </a>
                          <a
                            className=" social-link hint--bounce hint--bottom-left"
                            aria-label="Instagram"
                            href="https://instagram.com"
                            data-hover="Instagram"
                            target="_blank"
                          >
                            <i className="social-icon fab fa-instagram" />
                          </a>
                          <a
                            className=" social-link hint--bounce hint--bottom-left"
                            aria-label="Linkedin"
                            href="https://linkedin.com"
                            data-hover="Linkedin"
                            target="_blank"
                          >
                            <i className="social-icon fab fa-linkedin" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* mobile menu */}
                    <div
                      className="mobile-navigation-icon d-block d-xl-none"
                      id="mobile-menu-trigger"
                    >
                      <i />
                    </div>
                    {/* hidden icons menu */}
                    <div
                      className="hidden-icons-menu d-block d-md-none"
                      id="hidden-icon-trigger"
                    >
                      <a href="#">
                        <i className="far fa-ellipsis-h-alt" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom-wrap border-top d-md-block d-none header-sticky">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="header-bottom-inner">
                    <div className="header-bottom-left-wrap">
                      {/* navigation menu */}
                      <div className="header__navigation d-none d-xl-flex">
                        <nav className="navigation-menu">
                          <ul>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to="#">
                                <a>
                                  <span>Our Services</span>
                                </a>
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link to="/dashboard/placeorder">
                                    <a>
                                      <span>Academic Help</span>
                                    </a>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/dashboard/placeorder">
                                    <a>
                                      <span>Programming Help</span>
                                    </a>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/dashboard/placeorder">
                                    <a>
                                      <span>Calculation Help</span>
                                    </a>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                            <HashLink smooth to='/dashboard/placeorder' > How to Order </HashLink>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to="#">
                                <a>
                                  <span>Sample Work</span>
                                </a>
                              </Link>
                              <ul className="submenu">
                                <li>
                                <a href = {BusinessPlan} target = "_blank">Business Plan Paper</a>
                                </li>
                                <li>
                                <a href = {LiteraturePaperDoc} target = "_blank">Literature Paper</a>
                                </li>
                                <li>
                                <a href = {DissertationDoc} target = "_blank">Dissertation Paper</a>
                                </li>
                                <li>
                                <a href = {PaperReviewDoc} target = "_blank">Paper Review Paper</a>
                                </li>
                                <li>
                                <a href = {SocialPaperDoc} target = "_blank">Social Paper</a>
                                </li>
                                <li>
                                <a href = {TermPaperDoc} target = "_blank">Term Paper</a>
                                </li>
                                <li>
                                <a href = {TechnologyPaperDoc} target = "_blank">Technology Paper</a>
                                </li>
                                <li>
                                <a href = {DocumentationPaperDoc} target = "_blank">Documentation Paper</a>
                                </li>

                              </ul>
                            </li>
                            <li>
                              <Link to="/dashboard/placeorder">
                                <a>
                                  <span>Latest Reviews</span>
                                </a>
                              </Link>
                            </li>
                            <li>
                              <Link to="/dashboard/placeorder">
                                <a>
                                  <span>Pricing</span>
                                </a>
                              </Link>
                            </li>
                          </ul>
                        </nav>
                        {token !== null ? (
                          <Link to="/log">
                            <a 
                              style={{
                                borderRadius:"25%",
                              }}
                            
                            href="#" className="ht-btn ht-btn-md">
                              <i className="far fa-plus-circle me-2" />

                        
                              Log In
                            </a>
                          </Link>
                        ) : (
                          <Link to="/log">
                            <a className="ht-btn ht-btn-md">
                              <i className="far fa-user me-2" />
                              Order now
                            </a>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-carousel"  ref={scrollRef}>
        <Carousel activeIndex={index} onSelect={handleSelect}>

             {/* Carousel one */}
          <Carousel.Item>
            <div className="carousel1">
              <div className="container-fluid">
                <div className="row align-items-center h-full">
                  <div className="col-lg-6 col-md-7">
                    <div className="cybersecurity-hero-text">
                      <h1
                        style={{ color: "whitesmoke" }}
                      >
                     Quality Writing Services: Get Exceptional Help for Your Assignments
                      </h1>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "light",
                          color: "whitesmoke",
                        }}
                      >
                        Don't let assignments overwhelm you. Our writing services offer professional support 
                        to help you tackle any academic task with confidence. 
                        With prompt customer support and a focus on your success, we ensure a stress-free and rewarding experience.
                      </p>
                      <div className="carousel-links mt-30">
                        {token === null ? (
                          <Link to="/log">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        ) : (
                          <Link to="/dashboard/placeorder">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        )}
                        {token === null ? (
                          <Link to="/register">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Create Account
                            </a>
                          </Link>
                        ) : (
                          <Link to="/log">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Dashboard
                            </a>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    <div className="caption-img">
                      <img src={hero15} alt="hero" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

   {/* Carousel two */}
                    <Carousel.Item>
            <div className="carousel1">
              <div className="container-fluid">
                <div className="row align-items-center h-full">
                  <div className="col-lg-6 col-md-7">
                    <div className="cybersecurity-hero-text">
                      <h1
                        style={{ color: "whitesmoke" }}
                      >
                        Expert Assignment Help: High-Quality Writing Services for Students
                      </h1>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "light",
                          color: "whitesmoke",
                        }}
                      >
                        With our comprehensive assignment help services, you can simplify your academic journey. 
                        From essays to articles, we provide a one-stop solution for all your writing needs. 
                        Our experienced writers ensure timely delivery and maintain the highest standards of quality.
                      </p>
                      <div className="carousel-links mt-30">
                        {token === null ? (
                          <Link to="/log">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        ) : (
                          <Link to="/dashboard/placeorder">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        )}
                        {token === null ? (
                          <Link to="/register">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Create Account
                            </a>
                          </Link>
                        ) : (
                          <Link to="/log">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Dashboard
                            </a>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    <div className="caption-img">
                      <img src={hero2} alt="hero" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

           {/* Carousel three */}

                    <Carousel.Item>
            <div className="carousel1">
              <div className="container-fluid">
                <div className="row align-items-center h-full">
                  <div className="col-lg-6 col-md-7">
                    <div className="cybersecurity-hero-text">
                      <h1
                        className="font-weight--reguler mb-15"
                        style={{ color: "whitesmoke" }}
                      >
                        Expert Writers for All Your Assignment Needs
                      </h1>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "light",
                          color: "whitesmoke",
                        }}
                      >
                          Struggling with challenging assignments? Our dedicated team at 
                          is here to provide you with expert assistance. Whether it's essay writing, 
                          article writing, or any other academic task, we offer reliable solutions that help you achieve outstanding results.
                      </p>
                      <div className="carousel-links mt-30">
                        {token === null ? (
                          <Link to="/log">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        ) : (
                          <Link to="/dashboard/placeorder">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        )}
                        {token === null ? (
                          <Link to="/register">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Create Account
                            </a>
                          </Link>
                        ) : (
                          <Link to="/log">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Dashboard
                            </a>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    <div className="caption-img">
                      <img src={hero17} alt="hero" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

           {/* Carousel four */}
          <Carousel.Item>
            <div className="carousel1">
              <div className="container-fluid">
                <div className="row align-items-center h-full">
                  <div className="col-lg-6 col-md-7">
                    <div className="cybersecurity-hero-text">
                      <h1
                        className="font-weight--reguler mb-15"
                        style={{ color: "whitesmoke" }}
                      >
                        Reliable and Timely Assignment Help Services
                      </h1>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "light",
                          color: "whitesmoke",
                        }}
                      >
                      With our custom essay writing services, you can expect top-notch content tailored to your specific 
                      requirements. Our writers are adept at conducting in-depth research and crafting original, 
                      well-structured essays that meet the highest academic standards. 
                      </p>
                      <div className="carousel-links">
                        {token === null ? (
                          <Link to="/log">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        ) : (
                          <Link to="/dashboard/placeorder">
                            <a
                              style={{
                                backgroundColor: "#d2a98e",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              <i className="far fa-plus-circle me-2" /> Place
                              Order
                            </a>
                          </Link>
                        )}
                        {token === null ? (
                          <Link to="/register">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Create Account
                            </a>
                          </Link>
                        ) : (
                          <Link to="/log">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "transparent",
                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Dashboard
                            </a>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    <div className="caption-img">
                      <img src={hero19} alt="hero" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

        </Carousel>
      </div>
      <div
        className="smooth-scroll"
        style={{
          display: scrolled ? "block" : "none",
          transition: "display .3s",
        }}
        onClick={() =>
          scrollRef.current?.scrollIntoView({ behavior: "smooth" })
        }
      >
        <ExpandLessIcon
          style={{ color: "#fff", fontWeight: "900", fontSize: "28px" }}
        />
      </div>
      <div className="section-space--ptb_100">
        <div className="about">
          <div className="about-pic">
            <img src={about_pic} alt="about-left" />
          </div>
          <div className="about-content">
            <h3 className="heading">
            Your One-Stop Solution for Assignment Help: Essays, Articles, and More <span className="text-color-primary"> </span>
              <br /> 
            </h3>
            <p
                        style={{
                          fontSize: 18,
                          color:"black",
                          
                        }}
                      >
      Looking for a one-stop solution for all your assignment needs? At our company, we understand the
      challenges students face when it comes to completing essays, articles, and other academic tasks. 
      Our comprehensive platform offers a wide range of assignment help services, covering essay writing,
      article composition, research papers, technical assignments, and more. Our dedicated team of expert
      writers is committed to delivering high-quality, original content that meets the highest academic standards. 
      Save time and effort by accessing all the help you need in one place. No matter the subject or complexity, 
      trust us to be your reliable partner in achieving academic success. Get personalized and
      top-notch assistance from our experienced team today.


                      </p>
            <div className="single-button-group">
              <div className="button-group-wrap mt-30">
                <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                  Creative Team
                </a>
                <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                  Friendly Support
                </a>
                <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                  Save Your Time
                </a>
                <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                  Original Samples
                </a>
                <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                  Strict Deadlines
                </a>
                <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                  Available 24/7
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="feature-images-wrapper bg-gradient section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_60">
                <h2 className="heading">
                  <span className="text-color-primary"> Our Academic Services </span>
                </h2>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="image-l-r-box">
            <div className="row">
              <div className="col image-box-area">
                <div className="row image-box-boder-box">
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                      style={{
                        backgroundColor: "#d2a98e",
                        border: "1px solid",
                        
                      }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-01-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Creative Writing</h6>
                          <div className="text">
                            Autobiography, flash fiction, Novel, play, poetry,
                            screenplay and short stories.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
        
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                      style={{
                        backgroundColor: "#d2a98e",
                        border: "1px solid",
                        
                      }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-02-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Research Paper</h6>
                          <div className="text">
                            Analytical, compare and constrast, interpretative,
                            Survey research paper.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 
                    
                    style={{
                      backgroundColor: "#d2a98e",
                      border: "1px solid",
                      
                    }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-03-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Term Paper</h6>
                          <div className="text">
                            Get a term paper in any format including IEEE, APA7, MLA, CHICAGO, and HAVARD.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                          style={{
                            backgroundColor: "#d2a98e",
                            border: "1px solid",
                            
                          }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-05-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Dissertation</h6>
                          <div className="text">
                            Get a term paper in any format including IEEE.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                  <div className="ht-box-images style-09" 

                          style={{
                            backgroundColor: "#d2a98e",
                            border: "1px solid",
                            
                          }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-01-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Statistics</h6>
                          <div className="text">
                            Experiments and Sampling, probability, hypothesis,
                            linear reggresion, correlation.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                          style={{
                            backgroundColor: "#d2a98e",
                            border: "1px solid",
                            
                          }}
                    
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-04-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Mathematics</h6>
                          <div className="text">
                            Algebra, trigonometry, matrix, geometry, calculus,
                            rate & proportion..etc.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                          style={{
                            backgroundColor: "#d2a98e",
                            border: "1px solid",
                            
                          }}
                    
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-05-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Engineering</h6>
                          <div className="text">
                            Algebra, trigonometry, matrix, geometry, calculus,
                            rate & proportion ..etc
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                            style={{
                              backgroundColor: "#d2a98e",
                              border: "1px solid",
                              
                            }}
                    
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-06-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Chemistry</h6>
                          <div className="text">
                            Thermo-chemistry, electrochemistry, periodic table,
                            chemical bonding,...etc.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                            style={{
                              backgroundColor: "#d2a98e",
                              border: "1px solid",
                              
                            }}
                    
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-02-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Web Programming</h6>
                          <div className="text">
                            HTML, Javascript, CSS, Python, PHP, RUBY, C#, JAVA,
                            C++,...etc.
                          </div>
                          <div className="more-arrow-link">
                          <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                            style={{
                              backgroundColor: "#d2a98e",
                              border: "1px solid",
                              
                            }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-06-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Mobile App Development</h6>
                          <div className="text">
                            Android and IOS application development
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                            style={{
                              backgroundColor: "#d2a98e",
                              border: "1px solid",
                              
                            }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-05-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">
                            Database Design and Optimization
                          </h6>
                          <div className="text">
                            MYSQL, SQL, ORACLE, Postgresql, MongoDB, EER
                            diagrams,...etc.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 image-box-boder">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-09" 

                          style={{
                            backgroundColor: "#d2a98e",
                            border: "1px solid",
                            
                          }}
                    >
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <img
                            src="assets/images/icons/mitech-processing-service-image-06-100x104.webp"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Graphic Design</h6>
                          <div className="text">
                            Logo design, web design, book covers, product
                            labels, business cards, software interfaces,...etc.
                          </div>
                          <div className="more-arrow-link">
                            {" "}
                            <Link to="/dashboard/placeorder"> Find more</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="claim-to-excel-area section-space--ptb_120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_60">
                <h2 className="heading">
                  How our company works <br /> Perfect Solution Makes a{" "}
                  <span className="text-color-primary"> Success.</span>
                </h2>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="claim-grid-group">
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-one">
                    <div className="icon">
                      <span className="icon-basic-anticlockwise" />
                    </div>
                    <div className="content">
                      <h6 className="sub-heading">01</h6>
                      <h5 className="heading">Placing an Order</h5>
                      <p className="text">
                        Press the button to navigate to the order page. Fill out
                        the order form. Provide any extra details regardding the
                        work to provide more clarity. Provide any necesary
                        attachment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-two">
                    <div className="icon">
                      <span className="icon-basic-life-buoy" />
                    </div>
                    <div className="content">
                      <h6 className="sub-heading">02</h6>
                      <h5 className="heading">Account</h5>
                      <p className="text">
                        All our customers have an account. An account helps you
                        track the order progress. An existing customer will be
                        redirected to the dashboard after placing an order. A
                        new customer will be redirected to the registration
                        page.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-three">
                    <div className="icon">
                      <span className="icon-basic-world" />
                    </div>
                    <div className="content">
                      <h6 className="sub-heading">03</h6>
                      <h5 className="heading">Payment</h5>
                      <p className="text">
                        Choose your preferred mode of payment. Making a payments
                        provides a guarantee to our team that the client is
                        serious. It also helps us match the work with the best
                        expert.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-four">
                    <div className="icon">
                      <span className="icon-basic-case" />
                    </div>
                    <div className="content">
                      <h6 className="sub-heading">04</h6>
                      <h5 className="heading">Expertise Selection</h5>
                      <p className="text">
                        Our team matches the work with the best experts on that
                        area. Selection of an expert depends on the clients
                        service selection including extra services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-five">
                    <div className="icon">
                      <span className="icon-basic-lock" />
                    </div>
                    <div className="content">
                      <h6 className="sub-heading">05</h6>
                      <h5 className="heading">Tracking Order Progress</h5>
                      <p className="text">
                        After placing an order, the order will appear on the
                        dashboard. The only thing remaining is you to wait for
                        order delivery. You can also ask about the order
                        progress by sending a message to the support team.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-one">
                    <div className="icon">
                      <span className="icon-basic-lock" />
                    </div>
                    <div className="content">
                      <h6 className="sub-heading">06</h6>
                      <h5 className="heading">Receive Order and Rate</h5>
                      <p className="text">
                        After the order is completed, its placed on the
                        dashboard and a message is sent to the client informing
                        them about order delivery. The only thing now left is
                        rating our services and making anothe order if you found
                        our services top notch.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team-member-wrapper bg-gray section-space--pt_100 section-space--pb_40">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title section-space--mb_60">
                
                <h3 className="heading">
                  Our <span className="text-color-primary">experienced </span>{" "}
                  experts. 
                </h3>
                <p className="text mt-30">
                  Meet some of the most talented members of our team. Our team is composed of experienced professionals who are passionate about providing the highest quality of service. We understand the importance of delivering results and strive to ensure that our clients receive the best from our company. 
                  We are knowledgeable in a wide range of topics, including business, finance, marketing, and technology. Our team will work with you to ensure that your assignments are completed accurately and efficiently. We are committed to providing the best customer service possible.{" "}
                </p>
                <div className="sider-title-button-box mt-30">
                <Link to="/log">
                            <a
                              style={{
                                color: "#d2a98e",
                                border: "1px solid #d2a98e",
                                backgroundColor: "blue",

                              }}
                              className="ht-btn ht-btn-md "
                            >
                              Join Us
                            </a>
                          </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-8 ht-team-member-style-one">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="grid-item">
                    <div className="ht-team-member">
                      <div className="team-image">
                        <img 

                              style={{
                                borderRadius:"50%",
                              }}
                        
                        className="img-fluid" src={team5} alt="team5" />
                      </div>
                      <div className="team-info">
                      <h4 className="name" style={{ fontWeight: "bold", textAlign: "center"}}>Emily Chenily</h4>
                      <h5 className="position text-color-primary" style={{ fontWeight: "bold", textAlign: "center"}}>Writer (Technical and Research)</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="grid-item">
                    <div className="ht-team-member">
                      <div className="team-image">
                        <img 
                        
                        style={{
                          borderRadius:"50%",
                        }}
                        
                        className="img-fluid" src={team2} alt="team2" />
                      </div>
                      <div className="team-info ">
                      <h4 className="name" style={{ fontWeight: "bold", textAlign: "center"}}>David Miller</h4>
                      <h5 className="position text-color-primary" style={{ fontWeight: "bold", textAlign: "center"}}>Task Manager</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="grid-item">
                    <div className="ht-team-member">
                      <div className="team-image">
                        <img 
                      
                      style={{
                        borderRadius:"50%",
                      }}  
                      
                        className="img-fluid" src={team6} alt="team6" />
                      </div>
                      <div className="team-info ">
                      <h4 className="name" style={{ fontWeight: "bold" }}>Chase Myles</h4>
                      <h5 className="position text-color-primary" style={{ fontWeight: "bold" }}>Software Developer</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="grid-item">
                    <div className="ht-team-member">
                      <div className="team-image">
                        <img 
                        
                        style={{
                          borderRadius:"50%",
                        }}
                        
                        className="img-fluid" src={team7} alt="team7" />

                      </div>
                      <div className="team-info">
                      <h4 className="name" style={{ fontWeight: "bold", textAlign: "center"}}>Chloe Johnson</h4>
                      <h5 className="position text-color-primary" style={{ fontWeight: "bold", textAlign: "center"}}>Quality Assurance Officer</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="technology-service-area technology-service-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title section-space--pt_60">
                <h1 className="text-white font-weight--bold" style={{ fontSize: "30px text-align: center padding-bottom:10px" }}>Our Services</h1>
                <h3 className="text-white">
                  <span className="text-color-secondary">tothemoonexperts</span>{" "}
                  – Top Academic Writing Company in the world.
                </h3>
                <p className="text-infos text-white mt-30">
                  Feel free to contact us at any time to say “write my essays
                  for me” or ask your questions. The friendly customer support
                  team of our essay service is here 24/7 to give you a helping
                  hand.
                </p>
                <div className="single-button-group">
                  <div className="button-group-wrap mt-30">
                    <a href="/dashboard/placeorder" className="ht-btn ht-btn-md ht-btn--outline">
                      Paper Review
                    </a>
                    <a href="/dashboard/placeorder" className="ht-btn ht-btn-md ht-btn--outline">
                      Research Paper
                    </a>
                    <a href="/dashboard/placeorder" className="ht-btn ht-btn-md ht-btn--outline">
                      IEEE Paper
                    </a>
                    <a href="/dashboard/placeorder" className="ht-btn ht-btn-md ht-btn--outline">
                      Programming
                    </a>
                    <a href="/dashboard/placeorder" className="ht-btn ht-btn-md ht-btn--outline">
                      Creative Writing
                    </a>
                    <a href="/dashboard/placeorder" className="ht-btn ht-btn-md ht-btn--outline">
                      Power Point
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-image section-space--pt_60">
                <img
                  src="assets/images/banners/soft-s5-img.webp"
                  className="img-fluid"
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial-slider-area bg-gray section-space--ptb_100" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-wrap text-center section-space--mb_40">
              <h1 className="text-black font-weight--bold" style={{ fontSize: "30px text-align: center padding-bottom:10px color: black" }}>Reviews</h1>
              <h3 className="text-black">
                  <span className="text-color-primary">What Customers Say About Us</span>
                </h3>
             
              </div>
              <div className="testimonial-slider" >
                <div className="swiper-container testimonial-slider__container">
                  <div className="swiper-wrapper testimonial-slider__wrapper">
                    <div className="swiper-slide">
                      <div className="testimonial-slider__one" 
                                                  style={{
                                                    backgroundColor: "#d2a98e",
                                                  }}
                      >
                        <div className="testimonial-slider--info">
                          <div className="testimonial-slider__media">
                            <img
                              src={testimonial3}
                              alt="testimonial1"
                              className="img-fluid"
                            />
                          </div>
                          <div className="testimonial-slider__author" 
                          >
                            <div className="testimonial-rating">
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                            </div>
                            <div className="author-info">
                              <h6 className="name">Alen K. </h6>
                              <span className="designation">Ukraine</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider__text">
                        As an international student, I faced language barriers and struggled with writing essays 
                        that met the academic standards. tothemoonexperts came to my rescue! Their talented writers not only crafted
                         impeccable essays for me but also took the time to understand my unique perspective and incorporate it into the content.
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="testimonial-slider__one" 
                                                  style={{
                                                    backgroundColor: "#d2a98e",
                                                  }}
                      >
                        <div className="testimonial-slider--info">
                          <div className="testimonial-slider__media">
                            <img
                              src={testimonial2}
                              alt="testimonial1"
                              className="img-fluid"
                            />
                          </div>
                          <div className="testimonial-slider__author">
                            <div className="testimonial-rating">
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                            </div>
                            <div className="author-info">
                              <h6 className="name">Watson. D</h6>
                              <span className="designation">United States</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider__text">
                        I struggled with technical assignments and was on the verge of giving up. That's when I discovered tothemoonexperts. 
                        Their team of technical experts went above and beyond to help me understand 
                        complex concepts and provided step-by-step guidance. Their patience and expertise made all the difference
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="testimonial-slider__one" 
                                                  style={{
                                                    backgroundColor: "#d2a98e",
                                                  }}
                      >
                        <div className="testimonial-slider--info">
                          <div className="testimonial-slider__media">
                            <img
                              src={hero19}
                              alt="testimonial1"
                              className="img-fluid"
                            />
                          </div>
                          <div className="testimonial-slider__author">
                            <div className="testimonial-rating">
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                            </div>
                            <div className="author-info">
                              <h6 className="name">Mark R.</h6>
                              <span className="designation">United States</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider__text">
                        I was overwhelmed with multiple assignments and had no idea how to manage them all. 
                        Thankfully, I found tothemoonexperts, and they truly became my academic savior! Their expert writers not only 
                        delivered high-quality essays on time but also provided valuable guidance throughout the process.
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="testimonial-slider__one" 
                                                  style={{
                                                    backgroundColor: "#d2a98e",
                                                  }}
                      >
                        <div className="testimonial-slider--info">
                          <div className="testimonial-slider__media">
                            <img
                              src={testimonial2}
                              alt="testimonial1"
                              className="img-fluid"
                            />
                          </div>
                          <div className="testimonial-slider__author">
                            <div className="testimonial-rating">
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                              <span className="fa fa-star" />
                            </div>
                            <div className="author-info">
                              <h6 className="name">Jason T. </h6>
                              <span className="designation">United States</span>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-slider__text">
                        I was struggling with a tight deadline and a complex research paper that seemed impossible to complete. 
                        That's when I stumbled upon tothemoonexperts. Their team of experts not
                         only delivered a well-researched and perfectly written paper on time but also provided me with valuable insights 
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="swiper-pagination swiper-pagination-t01 section-space--mt_30" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="footer" className="footer-container">
        <div className="overlay"></div>
        <div className="footer-section">
          <div className="footer1">
            <img src="assets/images/logo/scaled2.png" alt="footer-logo" />
            <p> <br/>
              We provide only the best services. We hire only the to notch
              experts . Hiring a professional expert will provide you with time
              qualified and unique assistance.
            </p>
            <div className="social-links">
              <div className="i">
                <FontAwesomeIcon icon={faFacebookF} />
              </div>
              <div className="i">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
              <div className="i">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </div>
              <div className="i">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </div>
          </div>
          <div className="footer2">
            <h1>Explore</h1>
            <ul>
              
              <li>Our Services</li>
              <li>About Us</li>
              <li>Meet Our Team</li>
              <li>Our Services</li>
              <li>Our Blog</li>
            </ul>
          </div>

          <div className="footer3">
            <h1>Contact Us</h1>
            <ul>
              <li>
                <LocalPhoneOutlinedIcon
                  style={{ fontSize: "24px", marginRight: "15px" }}
                />
                <p>+254728272483</p>
              </li>
              <li>
                <EmailOutlinedIcon
                  style={{ fontSize: "24px", marginRight: "15px" }}
                />
                <p>support@tothemoonexperts.com</p>
              </li>
              <li>
                <LocationOnOutlinedIcon
                  style={{ fontSize: "24px", marginRight: "15px" }}
                />
                <p>3102 Bartlett Avenue</p>
              </li>
            </ul>
          </div>

          <div className="footer4">
            <h1>Newsletter</h1>
            <div className="modern-it-newsletters-wrap text-center mt-20">
              <div className="section-title">
                <h6 className="heading mb-30">
                  Don’t miss our monthly newsletters with the best analyses.
                </h6>
              </div>
              <form action="#">
                <div className="contact-inner">
                  <input type="email" required placeholder="Enter Your Email" />
                </div>
                <div className="button-wrap mb-15">
                  <input type="submit" value="Subscribe" />
                </div>
                <p>Join 8998 subscribers.</p>
              </form>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="underline"></div>
          
          <h5>Copyright Klaus | 2023 Design By <a href="https://www.klaustechs.com/" target="blank" >klaustechs</a></h5>
        </div>
      </div>
    </div>
  );
}
