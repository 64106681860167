import './pages.css';
import {useState, useEffect} from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTwitter, faFacebookF, faLinkedinIn, faInstagram} from '@fortawesome/free-brands-svg-icons';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import logo1 from '../images/logo1.png';
import logo2 from '../images/logo2.png';

export default function TermsOfService(){

    const [open, setOpen] = useState(false);
    const [samples, setSamples] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [token, setToken] = useState(null);

    // slidenavbar
    const [isExpanded, setIsExpanded] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;

        if (offset > 200){
            setScrolled(true)
        }else{
            setScrolled(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    });

    useEffect(() => {
        checkToken()
    }, []);

    function checkToken(){
        let is_token = localStorage.getItem('token');
        setToken(is_token)
    };

    let navbarClasses = ['navigation'];
    
    if(scrolled){
        navbarClasses.push('scrolled')
    };

    const handleOpenOptions = () => {
        setOpen(!open)
        setSamples(false)
    };

    const handleOpenSamples = () => {
        setSamples(!samples)
        setOpen(false)
    };

    let displayOptions, displayIcon, displaySamples, sampleIcon, miniOptions, miniSamples;

    return(
        <div className='home-container'>
            <div className={navbarClasses.join(" ")}>

                <div className={isExpanded ? "navlinks expanded" : "navlinks"}>
                    <ul className="navbars">
                        <li>
                            <img src={logo1} alt="logo" />
                        </li>
                        <ul>
                            <li onClick={handleOpenOptions}>Our Services <span>{displayIcon}</span></li>
                            {miniOptions}
                            <li>How to Order</li>
                            <li onClick={handleOpenSamples}>Sample Work {sampleIcon}</li>
                            {miniSamples}
                            <li>Blog</li>
                            <li>Latest Reviews</li>
                            <li>Pricing</li>
                            <div className="order-slidebar">
                                {token !== null ?
                                <Link to="/dashboard/orders" style={{textDecoration: 'none'}}><li><Button variant="contained" size="small" startIcon={<AddCircleOutlineIcon />} style={{width: '130px', padding: '8px 0'}} >Dashboard</Button></li></Link>
                                :
                                <Link to="/log" style={{textDecoration: 'none'}}><li><Button variant="contained" size="small" startIcon={<PersonOutlineOutlinedIcon />} style={{width: '130px', padding: '8px 0'}} >Order now</Button></li></Link>
                                }
                            </div>
                        </ul>
                        {token !== null ?
                                <Link to="/dashboard/orders" style={{textDecoration: 'none'}}><li><Button variant="contained" size="small" startIcon={<AddCircleOutlineIcon />} style={{width: '130px', padding: '8px 0'}} >Dashboard</Button></li></Link>
                                :
                                <Link to="/log" style={{textDecoration: 'none'}}><li><Button variant="contained" size="small" startIcon={<PersonOutlineOutlinedIcon />} style={{width: '130px', padding: '8px 0'}} >Order now</Button></li></Link>
                        }
                    </ul>
                </div>

                {displayOptions}

                {displaySamples}

                <div className="hamburger">
                    <div className="mini-logo">
                        <img src={logo1} alt="logo" style={{width: '100px'}}/>
                    </div>
                    <div className="hamburg-menu" onClick={() => setIsExpanded(!isExpanded)}>
                        <span className="h-top"></span>
                        <span className="h-middle"></span>
                        <span className="h-bottom"></span>
                    </div>
                </div>

            </div>

            <div className="privacy-container">
                <h1>Terms of Condition, Refund Policy</h1>
                <p>Welcome to Tothemoonexperts. These terms and conditions are an agreement between all Users of the website (individually referred to as “You”, “Your”, or “User”) , (Wherever you live, these terms is entered between You and Tothemoonexperts, a company registered under the laws of the Region shall apply.
                By using our website, services and reading our content you are agreeing to be bound by the following terms and conditions. These terms may change or may be modified at any time, by continuing to use our website after such changes or modifications, indicates your agreement to new terms. If you do not agree to these Terms, You may not use, view, or subscribe to our website. These terms do not require a physical, electronic or digital signature, it will be deemed and is hereby agreed with every User that he/she has read the content of this document.
                BEFORE YOU PROCEED, Kindly read these terms and conditions. Your decision to use Tothemoonexperts is a consent given unconditionally under law.</p>
                <h2>Use of the Service</h2>
                <p>The Service is intended for personal and non-commercial use. You may use the Service for lawful purposes only. You may not use the Service to transmit, distribute, or store material that is in violation of any applicable law or regulation.</p>
                <h2>Intellectual Property Rights</h2>
                <p>All content and materials available on the Service, including but not limited to text, graphics, logos, images, and software, are the property of Tothemoonexperts or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use any content or materials on the Service for any commercial purpose without the express written consent of Tothemoonexperts.</p>
                <h2>Disclaimer of Warranties</h2>
                <p>The Service is provided on an "as is" and "as available" basis. Tothemoonexperts makes no representations or warranties of any kind, express or implied, as to the operation of the Service or the information, content, materials, or products included on the Service. Tothemoonexperts does not warrant that the Service will be uninterrupted or error-free, and Tothemoonexperts will not be liable for any interruptions or errors.</p>
                <h2>Indemnification</h2>
                <p>You agree to indemnify, defend, and hold Tothemoonexperts, its affiliates, officers, agents, and employees harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable attorneys' fees and costs, arising out of or in any way connected with your use of the Service.</p>
                <h2>Contact Us</h2>
                <p style={{marginBottom: '0'}}>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <span style={{color: '#3367d6'}}>support@tothemoonexperts.com</span></p>
            </div>

            <div className="footer-container" style={{marginTop: '0'}}>
                <div className="overlay"></div>
                    <div className="footer-section">
                        <div className="footer1">
                            <img src={logo2} alt="footer-logo" />
                            <p>We provide only the best services. We hire only the to notch experts . Hiring a professional expert will provide you with time qualified and unique assistance.</p>
                            <div className="social-links">
                                <div className="i">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </div>
                                <div className="i">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </div>
                                <div className="i">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </div>
                                <div className="i">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </div>
                            </div>
                        </div>
                        <div className="footer2">
                            <h1>Explore</h1>
                            <ul>
                                <li>Our Services</li>
                                <li>About Us</li>
                                <li>Meet Our Team</li>
                                <li>Our Services</li>
                                <li>Our Blog</li>
                            </ul>
                        </div>

                        <div className="footer3">
                            <h1>Contact Us</h1>
                            <ul>
                                <li>
                                    <LocalPhoneOutlinedIcon style={{fontSize: '24px', marginRight: '15px'}}/>
                                <p>+254728272483</p>
                                </li>
                                <li>
                                    <EmailOutlinedIcon style={{fontSize: '24px', marginRight: '15px'}}/>
                                    <p>support@tothemoonexperts.com</p>
                                </li>
                                <li>
                                    <LocationOnOutlinedIcon style={{fontSize: '24px', marginRight: '15px'}}/>
                                    <p>3102 Bartlett Avenue</p>
                                </li>
                            </ul>
                        </div>

                    <div className="footer4">
                        <h1>Newsletter</h1>
                        <form>
                            <input type='email' required 
                                placeholder="Enter Your Email"
                            />
                            <input type='submit' value='Subscribe' />
                        </form>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="underline"></div>
                    <h5>Copyright 2023 Klaus | Design By Klaus Lab</h5>
                </div>
            </div>
        </div>
    )
}