import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './payment.css';
import {useNavigate} from 'react-router-dom';
import creditcard from '../images/debit-cards.png';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";

const currency = "USD";
const style = {"layout":"vertical"};

const ButtonWrapper = ({ currency, showSpinner }) => {
    let navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [ref, setRef] = useState(null);

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    
    useEffect(() => {

        if(!localStorage.getItem('token') || localStorage.getItem('token') === 'Wrong credentials'){
            return navigate('/', {replace: true})
        }

        const url = window.location.pathname
        const field = url.split('/')
        const id = field[3]

        async function fetchData(){
            const data = await fetch(`https://tothemoonexperts-api.herokuapp.com/dashboard/neworder/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application.json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            })
            const res = await data.json()
            setAmount(res.details.amount)
            setRef(res.id)
        }

        fetchData()

    },[navigate]);

    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                            application_context: {
                                    shipping_preference: "NO_SHIPPING",
                                },
                        })
                        .then((orderId) => {
                            console.log(orderId);
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        fetch('https://tothemoonexperts-api.herokuapp.com/card/capture-payment', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Token ${localStorage.getItem('token')}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({'ref': ref})
                        })
                        return navigate('/pay/success', {replace: true})
                    });
                }}
                onError={(err) => {
                    return navigate('/pay/invalid', {replace: true})
                }}
            />
        </>
    );
}

function Payment(){
    return (
        <div className="pay-form-container">
        <div className="proceed">
            <Link to="/dashboard/orders" style={{textDecoration: 'none'}}><button>Return to dashboard</button></Link>
        </div>
        <div className='pay-form'>
            <div className='payment'>
                <PayPalScriptProvider
                    options={{
                        "client-id": "AefBidgTPbxhEOHTkXvqtX4hiBLU_IWmOnckCXXXUbOM3-AGaHqnTu71W_gtfx5bAnOurZu5ir7N8yYw",
                        components: "buttons",
                        currency: "USD"
                    }}
                >
                    <ButtonWrapper
                        currency={currency}
                        showSpinner={false}
                    />
                </PayPalScriptProvider>
            </div>
            <div className='price'>
                <img src={creditcard} alt="credit cards" />
                <div className="card-content">
                    <h2>Enter Card Details</h2>
                    <p>Enter card details to proceed with this payment</p>
                    <p>Add your phone number to receive important SMS notifications <br/> about your order(s) and account.</p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Payment;